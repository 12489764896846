export function initInline ({ element, autoHeight}) {
	if (!element) {
		console.error('ScoreAppWidget: Element is required')
		return
	}


	element.appendChild(this.iframe)


	if (autoHeight){
		const  handleScroll = () => {

			if (!this.iframe) return;

			this.iframe.contentWindow.postMessage({
				iframe: {
					rect: this.iframe.getBoundingClientRect(),
					innerHeight: window.innerHeight,
					innerWidth: window.innerWidth,
				},
			}, '*');
		}
		const pageClasses = {
			question: 'sa--page-question',
			landing: 'sa--page-landing',
			result: 'sa--page-result',
		}

		const handleIframeResize = (event) => {
			try {
				const type = event.data.scoreapp && event.data.scoreapp.type

				this.iframe.style.height = `${event.data.scoreapp.height}px`;
				Object.values(pageClasses).forEach((className) => {
					this.iframe.classList.remove(className)
				})
				const className = pageClasses[type]

				if (className) {
					this.iframe.classList.add(className)
				}

				handleScroll();

			} catch (e) {}
		}

		window.addEventListener('message', handleIframeResize);
		window.addEventListener('resize', handleScroll);
		window.addEventListener('scroll', handleScroll, true);

		this.iframe.addEventListener('load', () => {
			this.iframe.contentWindow.postMessage({
				scoreapp: {
					autoHeight: true,
				}
			}, '*');

			this.iframe.style.height = '100vh';
			handleScroll();
		});

		element.style.height = 'auto';
	}
}
