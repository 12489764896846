export function initFull(element){
	if (!element) {
		console.error('ScoreAppWidget: Element is required')
		return
	}

	const createStyle = () => {
		const style = document.createElement('style')

		style.innerText = `
		.sa-full {
			z-index: 1000000;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		.sa--full-iframe-content {
			width: 100% !important;
			height: 100% !important;
			margin: 0,
			border: none;
		}
		`
		return style
	}

	const style = createStyle()

	document.head.appendChild(style)
	document.body.style.overflow = 'hidden'
	this.iframe.classList.add('sa--full-iframe-content')
	const div = document.createElement("div")
	div.classList.add('sa-full')
	div.appendChild(this.iframe)
	document.body.appendChild(div)
}
