
export function initSlider ({ element, size, position = 'left' }){

	if (!element) {
		console.error('ScoreAppWidget: Element is required')
		return
	}
	const isLeftPosition = position === 'left'
	let loaded = false;

	const createStyle = ()=> {
		const style = document.createElement('style')

		const SIZES = Object.freeze({
			small: '540px',
			medium: '720px',
			full: '100%'
		})

		style.innerText = `
		.sa--slider {
			width: 100vw;
			height: 100vh;
			height: -webkit-fill-available;
			position: fixed;
			background-color: rgba(0, 0, 0, .5);
			top: 0;
			left: 0;
			display: none;
			z-index: 99999999;
		}

		.sa--slider-iframe-wrap {
			position: fixed;
			width: ${SIZES[size]};
			height: 100%;
			max-width: calc(100% - 30px);
			max-height: 100vh;
			background-color: #ffffff;
			border-radius: 9px;
			top: 0px;
			${isLeftPosition ? 'left: 0px ': 'right: 0px'}
		}

		.sa--slider-iframe-content {
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 8px;
		}

		.sa--slider-close {
			display: inline-block;
		    color: #ffffff;
			font-size: 30px;
			cursor: pointer;
			position: absolute;
			top: 0;
			background-color: transparent;
			border: none;
			padding: 0;
			${isLeftPosition ? 'right: -30px': 'left: -30px'}
			}
			.sa--loading *:not(.sa--loader) {
			opacity: 0 !important;
		}
		.sa--loader {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 2em;
			height: 2em;
			transform: translate(-50%, -50%);
		}
		.sa--loader:after {
			box-sizing: border-box;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			content: "";
			display: block;
			width: 80%;
			height: 80%;
			border-radius: 50%;
			border: 2px solid currentColor;
			border-color: currentColor transparent currentColor transparent;
			animation: sa--loader 0.6s linear infinite;
		}
		@keyframes sa--loader {
			0% {
				transform: translate(-50%, -50%) rotate(0deg);
			}
			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}
		`
		return style
	}


	const createSlider = () => {
		const slider = document.createElement('div');
		slider.classList.add('sa--slider')

		return slider
	}

	const createCloseBtn = ()=> {

		const closeBtn = document.createElement('button')
		closeBtn.innerText = '×';
		closeBtn.classList.add('sa--slider-close')

		return closeBtn

	}

	const createIframeWrap = () => {

		const iframeWrap = document.createElement('div')
		iframeWrap.classList.add('sa--slider-iframe-wrap')

		return iframeWrap
	};

	const createLoader = () => {
		const element = document.createElement('span');

		element.classList.add('sa--loader');

		return element
	}

	const createToggleSlider = (slider, iframeWrap )=>{
		const originalPosition = element.style.position;
		const loaderElement = createLoader();

		return () => {
			if (slider.style.display === 'block') {

				const animatePosition = isLeftPosition ?
					[{ transform: 'translateX(0%)'}, { transform: 'translateX(-100%)'}] :
					[{ transform: 'translateX(0%)'}, { transform: 'translateX(100%)'}]

				const animation = iframeWrap.animate(animatePosition,
					{ duration: 250 })

				animation.finished.then(() => {
					slider.style.display = 'none';
					element.style.position = originalPosition;
					animation.cancel();
				})

				document.body.style.overflow = 'auto'
			} else {
				const openSlider = () => {
					loaderElement.remove();
					element.removeAttribute('disabled');
					element.classList.remove('sa--loading');
					const animatePosition = isLeftPosition ?
						[{ transform: 'translateX(-100%)'}, { transform: 'translateX(0)'}] :
						[{ transform: 'translateX(100%)'}, { transform: 'translateX(0%)'}]

					document.body.style.overflow = 'hidden'
					slider.style.display = 'block';
					const animation = iframeWrap.animate(animatePosition,
						{ duration: 400 })

					animation.finished.then(() => {
						animation.cancel();
					})
				}
				if (this.iframe.parentElement !== iframeWrapper) {
					const styles = getComputedStyle(element);
					if (styles.position === 'static') {
						element.style.position = 'relative';
					}
					element.classList.add('sa--loading');
					element.appendChild(loaderElement);
					this.iframe.addEventListener('load', () => {
						loaded = true;
						openSlider();
					});
					iframeWrapper.appendChild(this.iframe);
				}
				if (!loaded) {
					element.setAttribute('disabled', 'disabled');
					return;
				}

				openSlider();
			}
		}

	}
	const style = createStyle()
	const slider = createSlider()
	const iframeWrapper = createIframeWrap();
	const closeBtn = createCloseBtn()
	const toggleSlider = createToggleSlider(slider, iframeWrapper)


	element.addEventListener('click', toggleSlider )
	closeBtn.addEventListener('click', toggleSlider )


	this.iframe.classList.add('sa--slider-iframe-content')
	document.head.appendChild(style)

	slider.appendChild(iframeWrapper);
	iframeWrapper.appendChild(closeBtn);
	document.body.appendChild(slider);

}
