import { calcContrastColor } from './utils/colors'

export function initChat({ buttonBgColor = '', buttonColor, icon, isAutoOpen, text= '', fontSize = '16'  }) {

	buttonColor = buttonColor || calcContrastColor(buttonBgColor);
	let loaded = false;

	const createStyle = () => {
		const style = document.createElement('style')

		style.innerText = `
		.sa--chat {
			width: 100vw;
			height: 100vh;
			height: -webkit-fill-available;
			position: fixed;
			background-color: rgba(0, 0, 0, .5);
			top: 0;
			left: 0;
			display: none;
			z-index: 99999999;
		}

		.sa--chat-iframe-wrap {
			position: fixed;
			width: 300px;
			height: 500px;
			max-width: 100%;
			max-height: 500px;
			background-color: #ffffff;
			border-radius: 9px;
			bottom: 100px;
			right: 0;
			max-height: calc(100% - 80px);
		}

		.sa--chat-iframe-content {
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 8px;
		}
		.sa--chat-button {
			min-width: 2.125em;
			min-height: 2.125em;
			z-index: 9999999999;
		}

		.sa--chat-visibility {
			display: flex;
			justify-content: center;
			align-items: center;
			color: ${buttonColor};
			font-size: ${fontSize}px;
			cursor: pointer;
			position: fixed;
			right: 28px;
			bottom: 28px;
			background-color: ${buttonBgColor};
			border: none;
			padding: 0;
			border-radius: 50%;
			width: 2.125em;
			height: 2.125em;
			line-height: 2.125em;
		}

		.sa--chat-visibility__text {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: ${buttonColor};
			font-size: ${fontSize}px;
			cursor: pointer;
			position: fixed;
			right: 28px;
			bottom: 28px;
			background-color: ${buttonBgColor};
			border: none;
			padding: 0.5em 0.9em;
			border-radius: 1.8em;
		}

		.icon {
			width: 1.1em;
		}

		.icon:not(:only-child)  {
			margin-right: clamp(7px, 0.8em, 17px);
		}

		.sa-button_text {
			font-size: inherit;
		}

		@media screen and (max-width: 540px) {
			.sa--chat-iframe-wrap {
				width: 100%;
				height: 100%;
				max-height: 100%;
				bottom: 0;
			}
		}

		.sa--close {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 2em;
			height: 2em;
			transform: translate(-50%, -50%);
		}
		.sa--close:before {
			position: absolute;
			content: "";
			left: 50%;
			top: 50%;
			width: 60%;
			height: 2px;
			margin-top: -1px;
			background: ${buttonColor};
			transform: translate(-50%, -50%) rotate(45deg);
		}
		.sa--close:after {
			position: absolute;
			content: "";
			left: 50%;
			top: 50%;
			width: 60%;
			height: 2px;
			margin-top: -1px;
			background: ${buttonColor};
			transform: translate(-50%, -50%) rotate(-45deg);
		}
		.sa--loader {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 2em;
			height: 2em;
			transform: translate(-50%, -50%);
		}
		.sa--loader:after {
			box-sizing: border-box;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			content: "";
			display: block;
			width: 80%;
			height: 80%;
			border-radius: 50%;
			border: 2px solid currentColor;
			border-color: currentColor transparent currentColor transparent;
			animation: sa--loader 0.6s linear infinite;
		}
		@keyframes sa--loader {
			0% {
				transform: translate(-50%, -50%) rotate(0deg);
			}
			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}
		`

		return style
	}

	const createImage = () => {
		const img = document.createElement("img");
		img.classList.add('icon')
		img.src = icon

		return img
	}

	const createText = () => {
		const span = document.createElement("span");
		span.classList.add('sa-button_text');
		span.innerText = text;

		return span
	}

	const createChat = () => {
		const chat = document.createElement('div');
		chat.classList.add('sa--chat')

		return chat
	}

	const createChatButton = ()=> {
		const closeBtn = document.createElement('button')
		closeBtn.classList.add('sa--chat-button');

		closeBtn.classList.add(text ? 'sa--chat-visibility__text': 'sa--chat-visibility')

		if (icon){
			closeBtn.appendChild(createImage())
		}
		if (text) {
			closeBtn.appendChild(createText())
		}

		return closeBtn
	}

	const createIframeWrap = () => {

		const iframeWrap = document.createElement('div')
		iframeWrap.classList.add('sa--chat-iframe-wrap')

		return iframeWrap
	};

	const createLoader = () => {
		const element = document.createElement('span');

		element.classList.add('sa--loader');

		return element
	}

	const createClose = () => {
		const element = document.createElement('span');

		element.classList.add('sa--close');

		return element
	}

	const createToggleChat = (chat, iframeWrap, chatButton)=>{
		const imageElement = icon && createImage();
		const textElement = text && createText();
		const loaderElement = createLoader();
		const closeElement = createClose();

		return () => {
			const { matches } = window.matchMedia('(min-width: 540px)');
			if (chat.style.display === 'block') {

				const keyFramesAnimate = matches ?
					[{ height: '500px'}, { height: 0 }]:
					[{ height: 'calc(100% - 80px)'}, { height: 0 }]

				const animation = iframeWrap.animate(keyFramesAnimate, { duration: 500, fill: 'forwards' })

				animation.finished.then(() => {
					chatButton.innerHTML = '';
					if (imageElement) {
						chatButton.appendChild(imageElement)
					}
					if (textElement) {
						chatButton.appendChild(textElement)
					}

					chat.style.display = 'none';

					if (textElement) {
						chatButton.classList.replace('sa--chat-visibility', 'sa--chat-visibility__text')
					}

					animation.cancel();

				})

				document.body.style.overflow = 'auto'

			} else {
				const openChat = () => {
					chatButton.removeAttribute('disabled');
					const keyFramesAnimate = matches ?
						[{ height: 0 },{ height: '500px' }]:
						[{ height: 0 },{ height: 'calc(100% - 80px)' }];

					document.body.style.overflow = 'hidden'
					chat.style.display = 'block';


					chatButton.innerHTML= ''
					chatButton.appendChild(closeElement);
					chatButton.classList.replace('sa--chat-visibility__text', 'sa--chat-visibility')

					const animation = iframeWrap.animate(keyFramesAnimate, { duration: 500, fill: 'forwards' })
					animation.finished.then(() => {
						animation.cancel();
					})
				}
				if (this.iframe.parentElement !== iframeWrapper) {
					chatButton.innerHTML = '';
					chatButton.classList.replace('sa--chat-visibility__text', 'sa--chat-visibility');
					chatButton.appendChild(loaderElement);
					this.iframe.addEventListener('load', () => {
						loaded = true;
						openChat();
					});
					iframeWrapper.appendChild(this.iframe);
				}

				if (!loaded) {
					chatButton.setAttribute('disabled', 'disabled');
					return;
				}

				openChat();

			}
		}

	}

	const style = createStyle()
	const chat = createChat()
	const iframeWrapper = createIframeWrap();
	const chatButton = createChatButton()
	const toggleChat = createToggleChat(chat, iframeWrapper, chatButton)

	chatButton.addEventListener('click', toggleChat )

	this.iframe.classList.add('sa--chat-iframe-content')
	document.head.appendChild(style)

	chat.appendChild(iframeWrapper);
	document.body.appendChild(chat);
	document.body.appendChild(chatButton);



	if (isAutoOpen) {
		toggleChat()
	}
}
