
export function initPopup ({ element, size = 'medium'}) {
	if (!element) {
		console.error('ScoreAppWidget: Element is required')
		return
	}

	let loaded = false;

	const createStyle = ()=> {
		const style = document.createElement('style')

		const SIZES = Object.freeze({
			small: '540px',
			medium: '720px',
			full: '100%'
		})


		style.innerText = `
		.sa--popup {
			width: 100vw;
			height: 100vh;
			height: -webkit-fill-available;
			position: fixed;
			background-color: rgba(0, 0, 0, .5);
			top: 0;
			left: 0;
			display: none;
			z-index: 99999999;
		}

		.sa--popup-iframe-wrap {
			position: absolute;
			width: ${SIZES[size]};
			height: 80%;
			max-width: 100%;
			max-height: 100vh;
			top: 150%;
			left: 50%;
			background-color: #ffffff;
			transform: translate(-50%, -50%);
			border-radius: 9px;
		}

		.sa--popup-iframe-content {
			width: 100%;
			height: 100%;
			border: none;
			border-radius: 8px;
		}

		.sa--popup-close {
			display: inline-block;
			color: #ffffff;
			font-size: 30px;
			cursor: pointer;
			position: absolute;
			right: ${size === 'full' ? '12px' : 0};
			top: -40px;
			background-color: transparent;
			border: none;
			padding: 0;
		}

		.sa--loading *:not(.sa--loader) {
			opacity: 0 !important;
		}
		.sa--loader {
			position: absolute;
			left: 50%;
			top: 50%;
			width: 2em;
			height: 2em;
			transform: translate(-50%, -50%);
		}
		.sa--loader:after {
			box-sizing: border-box;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			content: "";
			display: block;
			width: 80%;
			height: 80%;
			border-radius: 50%;
			border: 2px solid currentColor;
			border-color: currentColor transparent currentColor transparent;
			animation: sa--loader 0.6s linear infinite;
		}
		@keyframes sa--loader {
			0% {
				transform: translate(-50%, -50%) rotate(0deg);
			}
			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}

		`
		return style
	}
	const createPopup = ()=> {
		const popup = document.createElement('div');
		popup.classList.add('sa--popup')
		return popup
	}
	const createCloseBtn = () => {

		const closeBtn = document.createElement('button')
		closeBtn.innerText = '×';
		closeBtn.classList.add('sa--popup-close')

		return closeBtn

	}

	const createIframeWrap = () => {

		const iframeWrap = document.createElement('div')
		iframeWrap.classList.add('sa--popup-iframe-wrap')

		return iframeWrap
	};

	const createLoader = () => {
		const element = document.createElement('span');

		element.classList.add('sa--loader');

		return element
	}

	const createTogglePopup = (popup, iframeWrap) => {
		const originalPosition = element.style.position;
		const loaderElement = createLoader();

		return () => {
			if (popup.style.display === 'block') {

				const animation = iframeWrap.animate([{ top: '50%;' }, { top: '150%' }], { duration: 250 })
				animation.finished.then(() => {
					popup.style.display = ''
					iframeWrap.style.top = ''
					document.body.style.overflow = ''
					element.style.position = originalPosition;
					animation.cancel();
				})

			} else {
				const openPopup = () => {
					loaderElement.remove();
					element.removeAttribute('disabled');
					element.classList.remove('sa--loading');
					document.body.style.overflow = 'hidden'
					popup.style.display = 'block';
					const animation = iframeWrap.animate([{ top: '150%;' }, { top: '50%' }], { duration: 400 })

					animation.finished.then(() => {
						iframeWrap.style.top = '50%'
						animation.cancel();
					})
				}
				if (this.iframe.parentElement !== iframeWrapper) {
					const styles = getComputedStyle(element);
					if (styles.position === 'static') {
						element.style.position = 'relative';
					}
					element.classList.add('sa--loading');
					element.appendChild(loaderElement);
					this.iframe.addEventListener('load', () => {
						loaded = true;
						openPopup();
					});
					iframeWrapper.appendChild(this.iframe);
				}
				if (!loaded) {
					element.setAttribute('disabled', 'disabled');
					return;
				}

				openPopup();
			}
		}

	}
	const style = createStyle();
	const popup = createPopup();
	const iframeWrapper = createIframeWrap();
	const closeBtn = createCloseBtn();
	const togglePopup = createTogglePopup(popup, iframeWrapper);
	element.addEventListener('click', togglePopup );
	closeBtn.addEventListener('click', togglePopup );


	this.iframe.classList.add('sa--popup-iframe-content')
	document.head.appendChild(style);

	popup.appendChild(iframeWrapper);
	iframeWrapper.appendChild(closeBtn);
	document.body.appendChild(popup);
}
